import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';   // Import for Firebase Authentication
import 'firebase/compat/firestore';  // Import for Firestore
import 'firebase/compat/storage';  // Import for Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAB10QoTXFcdUCaVSbZntf_d_cSj7OSjHM',
  authDomain: 'gottea-3daa4.firebaseapp.com',
  databaseURL: 'https://gottea-3daa4.firebaseio.com',
  projectId: 'gottea-3daa4',
  storageBucket: 'gottea-3daa4.appspot.com',
  messagingSenderId: '691417546594',
  appId: '1:691417546594:web:536ef6d0c379fb5947b63b',
  measurementId: 'G-ED1W31MB1J',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export Auth, Firestore, and Storage
const auth = firebase.auth();        // Initialize Firebase Auth
const firestore = firebase.firestore();  // Initialize Firestore
const storage = firebase.storage();   // Initialize Storage

export { auth, firestore, storage };
export default firebase;