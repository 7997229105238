// components/Confirm.js
import React from 'react';

const Confirm = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <p className="text-center text-gray-800">{message}</p>
        <div className="mt-4 flex justify-center">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-teal-500 text-white font-bold rounded hover:bg-teal-700 mr-5"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white font-bold rounded hover:bg-red-400"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;