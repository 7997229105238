import React from 'react';
import { useAuth } from '../AuthContext';

function Logout() {
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch {
      console.error("Failed to log out");
    }
  };

  return (
    <button onClick={handleLogout} className="bg-red-800 text-white px-4 py-2 rounded ml-4">
      Log Out
    </button>
  );
}

export default Logout;