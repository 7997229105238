import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Devices from './components/Devices';
import MenuItems from './components/MenuItems';
import Login from './components/Login';
import PrivateRoute from './PrivateRoute';
import Logout from './components/Logout';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppLayout>
          <Routes>
            <Route path="/" element={<PrivateRoute><MenuItems /></PrivateRoute>} />
            <Route path="/devices" element={<PrivateRoute><Devices /></PrivateRoute>} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </AppLayout>
      </AuthProvider>
    </Router>
  );
}

// Component for displaying the navigation and layout
function AppLayout({ children }) {
  const { currentUser } = useAuth();

  return (
    <div className="min-h-screen">
      {/* Show the nav bar only if the user is logged in */}
      {currentUser && (
        <nav className="bg-gt-white shadow mb-4 flex justify-center">
          <div className="container py-3 px-3 flex justify-between items-center">
            <div className="text-sm md:text-lg font-bold">Cafe TV Dashboard</div>
            <div className="text-sm md:text-lg">
              <Link to="/" className="mr-4 text-teal-500 hover:opacity-80">Menu Items</Link>
              <Link to="/devices" className="text-teal-500 hover:opacity-80">Devices</Link>
              <Logout/>
            </div>
          </div>
        </nav>
      )}
      <main>{children}</main>
    </div>
  );
}

export default App;