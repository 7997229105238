import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import AddMenuItem from './AddMenuItem';
import { PencilSquareIcon, TrashIcon, EyeIcon } from '@heroicons/react/24/solid';
import SlideshowModal from './SlideshowModal'; // Import SlideshowModal
import Alert from '../components/Alert';
import Confirm from '../components/Confirm';

function MenuItems() {
  const [alertMessage, setAlertMessage] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null); // Holds the item to edit
  const [showAdd, setShowAdd] = useState(false);
  const [showSlideshow, setShowSlideshow] = useState(false); // Controls slideshow modal
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const unsubscribe = firestore.collection('menuItems').onSnapshot(snapshot => {
      const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMenuItems(items);
      setLoading(false); // Set loading to false once data is fetched
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    // Query cafeTVs collection to check if any device is linked to the menuItem
    const querySnapshot = await firestore.collection('cafeTVs')
      .where('menuItemId', '==', id)
      .get();
  
    if (!querySnapshot.empty) {
      // If there are any devices linked, show a message and prevent deletion
      const linkedDevices = querySnapshot.docs.map(doc => {
        return `${doc.get("deviceName")}(${doc.get("storeCode")})`
      }).join(', ');
      setAlertMessage(`This menu item is linked to the following devices: ${linkedDevices}. Please unlink it before deleting.`);
    } else {
        setConfirmMessage('Are you sure you want to delete this menu item?');
        setConfirmCallback(() => async () => {
          await firestore.collection('menuItems').doc(id).delete();
          setAlertMessage('Menu item deleted successfully.');
          setConfirmMessage(null);
        });
    }
  };

  const handleEdit = (item) => {
    setCurrentItem(item); // Set current item for editing
    setShowAdd(true);
  };

  const handlePreview = (item) => {
    setCurrentItem(item); // Set the media list for the slideshow preview
    setShowSlideshow(true); // Show the slideshow modal
  };

  return (
    <div className='m-auto p-10 max-w-screen-xl'>
      {/* Alert Component */}
      {alertMessage && (
        <Alert
          message={alertMessage}
          onClose={() => setAlertMessage(null)}
        />
      )}
      {/* Confirm Component */}
      {confirmMessage && (
        <Confirm
          message={confirmMessage}
          onConfirm={confirmCallback}
          onCancel={() => setConfirmMessage(null)}
        />
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Menu Items</h2>
        <button
          onClick={() => {
            setCurrentItem(null); // Clear current item to add a new one
            setShowAdd(true);
          }}
          className="bg-teal-500 text-white px-4 py-2 rounded"
        >
          Add Menu Item
        </button>
      </div>
      {showAdd && (
        <AddMenuItem
          onClose={() => setShowAdd(false)}
          currentItem={currentItem} // Pass current item if in edit mode
        />
      )}

      {/* Loader */}
      {loading ? (
        <div className="text-center py-4">
          <div className="loader border-t-4 border-teal-500 border-solid rounded-full w-12 h-12 mx-auto animate-spin"></div>
          <p className='mt-2'>Loading menu items...</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow rounded text-left">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Title</th>
                <th className="py-2 px-4 border-b hidden lg:table-cell">Type</th>
                <th className="py-2 px-4 border-b hidden lg:table-cell">Media Count</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {menuItems.map((item) => (
                <tr key={item.id}>
                  <td className="py-2 px-4 border-b">{item.title}</td>
                  <td className="py-2 px-4 border-b hidden lg:table-cell">{item.type}</td>
                  <td className="py-2 px-4 border-b hidden lg:table-cell">{item.mediaList ? item.mediaList.length : 1}</td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => handleEdit(item)}
                      className="text-teal-500 mr-2 hover:opacity-70"
                    >
                      <PencilSquareIcon className="size-8" />
                    </button>
                    <button
                        onClick={() => handlePreview(item)}
                        className="text-teal-500 mr-2 hover:opacity-70"
                      >
                        <EyeIcon className='size-8'/>
                      </button>
                      <button
                      onClick={() => handleDelete(item.id)}
                      className="text-red-500 hover:opacity-70"
                    >
                      <TrashIcon className="size-8" />
                    </button>
                  </td>
                </tr>
              ))}
              {menuItems.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    No menu items found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Slideshow modal */}
      <SlideshowModal
        currentItem={currentItem}
        isOpen={showSlideshow}
        onClose={() => setShowSlideshow(false)}
      />
    </div>
  );
}

export default MenuItems;