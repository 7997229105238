import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlideshowModal = ({ currentItem, isOpen, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide
  const sliderRef = useRef(null); // Ref to access the Slider instance
  let mediaList = [];
  if(currentItem && currentItem.type.toLowerCase() === "slideshow"){
    mediaList = currentItem.mediaList;
  }
  useEffect(() => {
    if (isOpen) {
      if(currentItem && currentItem.type.toLowerCase() === "slideshow"){
        const currentMedia = mediaList[currentSlide];
        if (currentMedia.type === 'image') {
          const duration = currentMedia.duration || 5; // Default to 5 seconds
          const timer = setTimeout(() => {
            nextSlide();
          }, duration * 1000); // Set the timer to match the slide duration
  
          return () => clearTimeout(timer); // Clear the timer when the component unmounts or slide changes
        }
      }
    }
  }, [currentSlide, currentItem, isOpen]); // Run this effect whenever the slide changes or modal is opened

  const nextSlide = () => {
    if (sliderRef.current) {
      const nextIndex = (currentSlide + 1) % mediaList.length;
      sliderRef.current.slickGoTo(nextIndex);
      setCurrentSlide(nextIndex);
    }
  };

  const handleVideoEnd = () => {
    nextSlide();
  };

  const handleVideoPlay = (videoElement) => {
    videoElement.play();
    videoElement.onended = handleVideoEnd; // Move to the next slide after the video ends
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // We handle autoplay manually
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  if (!isOpen) return null;


  return currentItem ? <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={onClose}>
      <div className="bg-white p-0 rounded shadow-lg max-w-lg w-full border-solid" onClick={(e)=>{ e.stopPropagation()}}>
        {currentItem.type === "slideshow" ?
        <Slider {...settings} ref={sliderRef}>
          {mediaList.map((media, index) => (
              <div key={index} className="aspect-w-16 aspect-h-9">
                {media.type === 'image' ? (
                  <img
                    src={media.url}
                    alt={`Slide ${index + 1}`}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <video
                    src={media.url}
                    className="object-cover w-full h-full"
                    ref={(el) => el && currentSlide === index && handleVideoPlay(el)}
                    controls={false} // Remove controls for autoplay
                  />
                )}
              </div>
          ))}
        </Slider> : currentItem.type === "image" ? 
        <img alt="Preview of Current Slide" src={currentItem.imageUrl}/> : 
        <video 
          src={currentItem.imageUrl} 
          className="object-cover w-full h-full"
          ref={(el) => el && handleVideoPlay(el)}
          controls={false} 
        />}
      </div>
    </div> : ''
};

export default SlideshowModal;