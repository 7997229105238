import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { firestore, storage } from '../firebase';
import MediaPreview from './MediaPreview';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
const ItemType = 'MEDIA_ITEM';

// Draggable media item component
const MediaItem = ({ media, index, moveMediaItem, onEdit, onDelete }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveMediaItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <li
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      className="flex items-center justify-evenly mb-2 bg-blue-100 border-solid border-teal-500 p-2 rounded"
    >
      {media.url && (
        <div className="mt-2">
          <MediaPreview url={media.url} type={media.type} />
        </div>
      )}

      <div className="flex-col justify-between">
        <p>{media.type === 'image' ? `Duration: ${media.duration || 'N/A'}s` : ''}</p>
        <button type="button" className="text-teal-500 mr-10 hover:opacity-70" onClick={onEdit}>
          <PencilSquareIcon className="size-6" />
        </button>
        <button type="button" className="text-red-500 hover:opacity-70" onClick={onDelete}>
          <TrashIcon className="size-6" />
        </button>
      </div>
    </li>
  );
};

const AddMenuItem = ({ onClose, currentItem }) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('image');
  const [imageUrl, setImageUrl] = useState('');
  const [mediaList, setMediaList] = useState([]);
  const [mediaFile, setMediaFile] = useState(null);
  const [duration, setDuration] = useState(5);
  const [mediaType, setMediaType] = useState('image');
  const [editingMediaIndex, setEditingMediaIndex] = useState(null);
  const [mediaUrlToEdit, setMediaUrlToEdit] = useState(null); // Stores current media URL when editing

  useEffect(() => {
    if (currentItem) {
      setTitle(currentItem.title);
      setType(currentItem.type);
      if (currentItem.type === 'slideshow') {
        setMediaList(currentItem.mediaList || []);
      } else {
        setImageUrl(currentItem.imageUrl || '');
      }
    }
  }, [currentItem]);

  const handleAddMedia = async () => {
    let url = mediaUrlToEdit;

    if (mediaFile) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`menuItems/${mediaFile.name}-${Date.now()}`);
      const snapshot = await fileRef.put(mediaFile);
      url = await snapshot.ref.getDownloadURL();
    }

    if (editingMediaIndex !== null) {
      const updatedMediaList = [...mediaList];
      updatedMediaList[editingMediaIndex] = { duration, type: mediaType, url };
      setMediaList(updatedMediaList);
      setEditingMediaIndex(null);
    } else {
      setMediaList([...mediaList, { duration, type: mediaType, url }]);
    }

    setMediaFile(null);
    setDuration(5);
    setMediaType('image');
    setMediaUrlToEdit(null);

    document.getElementById('media-file-input').value = null;
  };

  const handleFileUpload = (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setMediaFile(file);
    setMediaType(file.type.startsWith('image') ? 'image' : 'video');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      alert('Please provide a title.');
      return;
    }

    let newItem = {
      title,
      type,
    };

    if (type === 'slideshow') {
      if (mediaList.length === 0) {
        alert('Please add at least one media item for the slideshow.');
        return;
      }
      newItem.mediaList = mediaList;
    } else {
      if (!imageUrl && mediaFile) {
        // Upload the file and get the URL for non-slideshow types
        const storageRef = storage.ref();
        const fileRef = storageRef.child(`menuItems/${mediaFile.name}-${Date.now()}`);
        const snapshot = await fileRef.put(mediaFile);
        const url = await snapshot.ref.getDownloadURL();
        newItem.imageUrl = url;
      } else {
        newItem.imageUrl = imageUrl;
      }
    }

    if (currentItem) {
      await firestore.collection('menuItems').doc(currentItem.id).update(newItem);
    } else {
      await firestore.collection('menuItems').add(newItem);
    }

    onClose();
  };

  const handleEditMedia = (index) => {
    const media = mediaList[index];
    setEditingMediaIndex(index);
    setMediaType(media.type);
    setDuration(media.duration || 5);
    setMediaUrlToEdit(media.url); // Store current media URL to edit
  };

  const handleDeleteMedia = (index) => {
    const updatedMediaList = mediaList.filter((_, i) => i !== index);
    setMediaList(updatedMediaList);
  };

  const moveMediaItem = (fromIndex, toIndex) => {
    const updatedMediaList = Array.from(mediaList);
    const [movedItem] = updatedMediaList.splice(fromIndex, 1);
    updatedMediaList.splice(toIndex, 0, movedItem);
    setMediaList(updatedMediaList);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
        <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg max-h-svh overflow-y-scroll">
          <h3 className="text-xl font-bold mb-4">{currentItem ? 'Edit Menu Item' : 'Add Menu Item'}</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-1">Title</label>
              <input
                type="text"
                className="w-full border px-3 py-2 rounded"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Type</label>
              <select
                className="w-full border px-3 py-2 rounded"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="image">Image</option>
                <option value="video">Video</option>
                <option value="slideshow">Slideshow</option>
              </select>
            </div>

            {/* Image/Video URL for type "image" or "video" */}
            {type !== 'slideshow' && (
              <div className="mb-4">
                <label className="block mb-1">{type === 'image' ? 'Image' : 'Video'} Upload</label>
                <input
                  type="file"
                  accept={type === 'image' ? 'image/*' : 'video/*'}
                  onChange={handleFileUpload}
                />
                {imageUrl && (
                  <div className="mt-2">
                    <p>Preview</p>
                    {type === 'image' ? (
                      <img src={imageUrl} alt="Preview" className="max-w-full h-auto" />
                    ) : (
                      <video src={imageUrl} controls className="max-w-full h-auto" />
                    )}
                  </div>
                )}
              </div>
            )}

            {/* Slideshow-specific form */}
            {type === 'slideshow' && (
              <div className="mb-4 rounded bg-blue-100 p-2">
                <h4 className="font-semibold mb-2">{editingMediaIndex !== null ? 'Edit Slide' : 'Add Media to Slideshow'}</h4>
                {mediaUrlToEdit && (
                  <div className="mb-2">
                    <p>Current Media</p>
                    <MediaPreview url={mediaUrlToEdit} type={mediaType} />
                  </div>
                )}
                <div className="flex items-center space-x-2 mb-2">
                  <input
                    id="media-file-input"
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileUpload}
                  />
                  {mediaType === 'image' && (
                    <div>
                      <p className="text-lg">Slide Duration (seconds)</p>
                      <input
                        type="number"
                        min="1"
                        className="w-24 border px-2 py-1 rounded"
                        value={duration}
                        onChange={(e) => setDuration(Number(e.target.value))}
                      />
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={handleAddMedia}
                  className="bg-green-500 text-white px-3 py-1 rounded"
                  disabled={!mediaFile && editingMediaIndex === null} // Enabled when editing without new media
                >
                  {editingMediaIndex !== null ? 'Update Slide' : 'Add Media'}
                </button>
                {editingMediaIndex !== null ? <button
                    type='button'
                    onClick={()=>{
                        setEditingMediaIndex(null);
                        setMediaFile(null);
                        setDuration(5);
                        setMediaType('image');
                        setMediaUrlToEdit(null);
                    }}
                    className="bg-red-500 text-white px-3 py-1 rounded ml-2"
                >Cancel</button> : ''}
              </div>
            )}

            {/* Display media list for slideshow */}
            {type === 'slideshow' && mediaList.length > 0 && (
              <div className="mb-4">
                <h4 className="font-semibold mb-2">Reorder Media List</h4>
                <ul className="list-disc list-inside">
                  {mediaList.map((media, index) => (
                    <MediaItem
                      key={media.url}
                      media={media}
                      index={index}
                      moveMediaItem={moveMediaItem}
                      onEdit={() => handleEditMedia(index)}
                      onDelete={() => handleDeleteMedia(index)}
                    />
                  ))}
                </ul>
              </div>
            )}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 rounded border"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-teal-500 text-white px-4 py-2 rounded"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </DndProvider>
  );
};

export default AddMenuItem;